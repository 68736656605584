import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "components/molecules/Layout"
import { loadStamped } from "../utils/stamped"

const ReviewPage = () => {
  useEffect(() => {
    loadStamped()
  })
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kiss My Keto Reviews</title>
        <meta
          property="og:description"
          content="Check Out Some of Our Reviews Here Read what our customers have to say about us."
        />
        <link rel="canonical" href="https://www.kissmyketo.com/" />
      </Helmet>
      <h1 className="review-header">Check Out Some of Our Reviews Here</h1>
      <div
        id="stamped-main-widget"
        data-product-id=""
        data-name="##product.title##"
        data-url="##product.url##"
        data-image-url="##product.image##"
        data-description="##product.description##"
        data-product-sku="##product.handle##"
      ></div>
    </Layout>
  )
}

export default ReviewPage
